import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossIcon } from 'icons/cross.svg';

import './CookieWarning.scss';

const CookieWarning = (): JSX.Element => {
  const { t, i18n } = useTranslation('common');
  const [isHide, setIsHide] = useState(() => Boolean(Cookies.get('cusg')));

  if (isHide) return <></>;

  const handlePostpone = () => {
    Cookies.set('cusg', 'true', { expires: 365, path: '/' });
    setIsHide(true);
  };

  return (
    <div className='lrd-ui--cookie-warning'>
      <p className='lrd-ui--cookie-warning__message'>
        {t('cookie_warning_message')}
        <a href={`https://help.lardi.com/${i18n.language}/privacy-policy.html#cookie`} target='_blank' rel='noreferrer'>
          {t('cookie_warning_link')}
        </a>
      </p>
      <button className='lrd-ui--cookie-warning__close' onClick={handlePostpone}>
        <CrossIcon />
      </button>
    </div>
  );
};

export default CookieWarning;
