import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'router';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';

import UserContext from '../../context/user';
import LangSwitcher from '../LangSwitcher';
import './style.scss';

const LoginHeader = () => {
  const { login } = useContext(UserContext);
  const { t } = useTranslation('auth');
  const location = useLocation();

  const isLoginRegister =
    matchPath(location.pathname, routes.login.path)?.isExact ||
    matchPath(location.pathname, routes.register.path)?.isExact;

  return (
    <header className={'header'}>
      {login ? (
        <Link to={routes.dashboard.path} className={'header_logo'}>
          <LogoIcon />
        </Link>
      ) : (
        <a href='/' className={'header_logo'}>
          <LogoIcon />
        </a>
      )}
      <div className={'header_switcher'}>
        {!isLoginRegister ? (
          <Link to={routes.login.path} className='button button_gray button_large'>
            {t('login_button')}
          </Link>
        ) : null}
        <LangSwitcher />
      </div>
    </header>
  );
};

export default LoginHeader;
