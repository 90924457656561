import React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import { getCookie } from 'utils/cookie';

import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/components/LabeledSelect';

import { defaultLang, lang } from '../../constants';
import './style.scss';

const LangSwitcher = () => {
  const { i18n } = useTranslation();

  const options = Object.keys(lang).map((l: string) => ({
    label: l.toUpperCase(),
    value: l
  })) as Option[];

  return (
    <Select
      value={options.find((opt) => opt.value === i18n.language) || options.find((opt) => opt.value === defaultLang)}
      name='lang'
      selectSize='large'
      wrapperClassName={'langSwitcher'}
      isSearchable={false}
      options={options}
      onChange={(opt: MultiValue<Option> | SingleValue<Option>) => {
        i18n.changeLanguage((opt as Option).value as string);
        // @ts-ignore
        if (window?.HelpCrunch) {
          // @ts-ignore set lang
          window?.HelpCrunch('setPhraseList', getCookie('NEXT_LOCALE'));
        }
      }}
    />
  );
};

export default LangSwitcher;
