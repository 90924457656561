import Clarity from '@microsoft/clarity';
import React, { lazy, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Layout from 'containers/layout';

import CookieWarning from '../components/CookieWarning';
import { API } from '../containers/freights/api';
import GenerateRoute from './generateRoute';
import { RoutesObject } from './interface';

export const routes: RoutesObject = {
  login: {
    path: '/login/',
    Component: lazy(() => import('containers/auth/containers/Login')),
    isPrivate: false
  },
  register: {
    path: '/register/',
    Component: lazy(() => import('containers/auth/containers/Register')),
    isPrivate: false
  },
  resetPassword: {
    path: '/reset-password/',
    Component: lazy(() => import('containers/auth/containers/ResetPassword')),
    isPrivate: false
  },
  setNewPassword: {
    path: '/recover/:id/',
    Component: lazy(() => import('containers/auth/containers/SetNewPassword')),
    isPrivate: false
  },
  verifyEmail: {
    path: '/verify-email/:id/',
    Component: lazy(() => import('containers/auth/containers/VerifyEmail')),
    isPrivate: false
  },
  system: {
    path: process.env.REACT_APP_SYSTEM_PAGE_OK as string,
    Component: () => (
      <ErrorBoundary fallback={<div>error fallback</div>}>
        <div>ok</div>
      </ErrorBoundary>
    ),
    isPrivate: false
  },
  verification: {
    path: '/verification/',
    Component: lazy(() => import('containers/settings/containers/verification')),
    isPrivate: true
  },
  create: {
    path: '/create/',
    Component: lazy(() => import('containers/freights/containers/edit')),
    isPrivate: true
  },
  edit: {
    path: '/edit/:id/',
    Component: lazy(() => import('containers/freights/containers/edit')),
    isPrivate: true
  },
  inactive: {
    path: '/inactive/',
    Component: lazy(() => import('containers/freights/containers/list')),
    isPrivate: true
  },
  settings: {
    path: '/settings/',
    Component: lazy(() => import('containers/settings/containers/settings')),
    isPrivate: true
  },
  dashboard: {
    path: '/dashboard/',
    Component: lazy(() => import('containers/freights/containers/list')),
    isPrivate: true
  },
  error: {
    path: '*',
    Component: lazy(() => import('containers/layout/containers/ErrorPage')),
    isPrivate: true
  }
};

const Router = () => {
  const { data, isFetched } = useQuery([`properties`], async () => API.getProperties());

  useEffect(() => {
    if (data?.clarityProjectId && isFetched) {
      Clarity.init(data?.clarityProjectId);
    }
  }, [data?.clarityProjectId]);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASEPATH}>
      <Layout>
        <Switch>
          <Route exact path='/'>
            <Redirect to={routes.dashboard.path} />
          </Route>

          {Object.keys(routes).map((key) => (
            <GenerateRoute
              key={routes[key].path}
              path={routes[key].path}
              Component={routes[key].Component}
              isPrivate={routes[key].isPrivate}
            />
          ))}
        </Switch>
      </Layout>
      <CookieWarning />
    </BrowserRouter>
  );
};

export default Router;
