import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { routes } from 'router';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import { ReactComponent as MainMenuIcon } from 'icons/mainMenu.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import { userLogout } from '../../../../network/loginActions';
import { API } from '../../api';
import UserContext from '../../context/user';
import './style.scss';

interface Props {
  notVerify?: boolean;
  toggleMenu: () => void;
}
const UserHeader: FC<Props> = ({ toggleMenu, notVerify }) => {
  const { t, i18n } = useTranslation('common');
  const { setLoginState, setUser } = useContext(UserContext);

  const logout = useMutation(API.logout, {
    onSuccess: () => {
      userLogout();
      setLoginState(false);
      setUser(null);
      window.location.replace(process.env.REACT_APP_BASEPATH + routes.login.path);
    }
  });

  return (
    <header className={'headerMain'}>
      <div className={'headerMain_left'}>
        {!notVerify ? (
          <div className={'headerMain_menu'}>
            <button onClick={toggleMenu}>
              <MainMenuIcon />
            </button>
          </div>
        ) : null}

        {notVerify ? (
          <a href={window.location.origin + '/' + i18n.language} className={'headerMain_logo'}>
            <LogoIcon />
          </a>
        ) : (
          <Link to={routes.dashboard.path} className={'headerMain_logo'}>
            <LogoIcon />
          </Link>
        )}
      </div>
      <div className={'headerMain_right'}>
        {notVerify ? (
          <button type='button' onClick={() => logout.mutate()} className={'button button_secondary button_large'}>
            {t('button_logout')}
          </button>
        ) : (
          <Link to={routes.create.path} className={'button button_secondary button_large'}>
            <PlusIcon />
            <span>{t('freights')}</span>
          </Link>
        )}
      </div>
    </header>
  );
};

export default UserHeader;
