import axios from 'axios';
import qs from 'query-string';

import { getCookie } from '../utils/cookie';
import { LOCAL_STORAGE_API_KEY_ACCESSOR } from './loginActions';
import response from './response';

const client = axios.create({
  paramsSerializer: (params) =>
    qs.stringify(params, {
      skipNull: true,
      skipEmptyString: true
    })
});

client.defaults.withCredentials = true;
client.defaults.timeout = 50000;
client.defaults.baseURL = '/web-api';

client.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(LOCAL_STORAGE_API_KEY_ACCESSOR);
    const lang = getCookie('NEXT_LOCALE');
    if (token) {
      config.headers['Authorization'] = token;
    }
    config.headers['Accept-Language'] = lang;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// @ts-ignore
client.interceptors.response.use(...response);

export default client;
