import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import i18n from '../i18n';
import { routes } from '../router';
import { userLogout } from './loginActions';

const config = [
  (response: AxiosResponse): AxiosResponse => {
    document.body.style.cursor = '';
    return response;
  },
  (error: AxiosError): any => {
    const status = error?.response?.status as number;

    document.body.style.cursor = '';

    // @ts-ignore
    if (status === 401 && error?.response?.data?.path !== '/web-api/auth/login') {
      userLogout();
      window.location.replace(process.env.REACT_APP_BASEPATH + routes.login.path);
    }

    // @ts-ignore
    if (status === 401 && error?.response?.data?.path === '/web-api/auth/login') {
      // @ts-ignore
      toast.error((error.response?.data?.message as string) || error?.message, {
        autoClose: 5000,
        pauseOnHover: false
      });
    }

    if (status < 500 && status >= 400 && status !== 422 && status !== 401) {
      // @ts-ignore
      toast.error((error.response?.data?.message as string) || error?.message, {
        autoClose: 5000,
        pauseOnHover: false
      });
    }
    if (status >= 500) {
      toast.error(i18n.t('common:error_message_500'), {
        autoClose: 5000,
        pauseOnHover: false
      });
    }

    return Promise.reject(error);
  }
];

export default config;
