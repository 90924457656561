import client from 'network';

import { User } from './context/user';

export const API = {
  async getCurrentUser(): Promise<User> {
    return await client.get('/log/user').then(({ data }) => data);
  },
  async logout(): Promise<void> {
    const { data } = await client.post('/auth/logout');
    return data;
  }
};
