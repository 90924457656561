import { createContext } from 'react';

import { MessengersEnum } from 'containers/settings/interface';

export interface User {
  email: string;
  emailVerified: boolean;
  name: string;
  id: number;
  phone: string;
  phoneMessengers: MessengersEnum[];
  phoneVerified: boolean;
  verified: boolean;
}

interface UserContextProps {
  login: boolean;
  user: User | null;
  setLoginState: (auth: boolean) => void;
  setUser: (user: any) => void;
}

const UserContext = createContext<UserContextProps>({
  login: false,
  user: null,
  setLoginState: (auth) => {},
  setUser: (user) => {}
});

export default UserContext;
