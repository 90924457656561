import classes from 'classnames';
import { userLogout } from 'network/loginActions';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import { routes } from 'router';

import ChangeLanguageMenu from 'containers/layout/components/ChangeLanguageMenu';
import UserContext from 'containers/layout/context/user';

import { ReactComponent as CargoIcon } from 'icons/cargo.svg';
import { ReactComponent as LogOutIcon } from 'icons/logOut.svg';
import { ReactComponent as NoAvatarIcon } from 'icons/noAvatar.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';
import { ReactComponent as UserIcon } from 'icons/user.svg';

import { API } from '../../api';
import './style.scss';

interface Props {
  open: boolean;
  mobileOpen: boolean;
  handleCloseMobileMenu: () => void;
}
const SidebarMenu: FC<Props> = ({ open, mobileOpen, handleCloseMobileMenu }) => {
  const { t } = useTranslation('common');
  const { user, setLoginState, setUser } = useContext(UserContext);

  const logout = useMutation(API.logout, {
    onSuccess: () => {
      handleCloseMobileMenu();
      userLogout();
      setLoginState(false);
      setUser(null);
      window.location.replace(process.env.REACT_APP_BASEPATH + routes.login.path);
    }
  });

  return (
    <div className={classes('sidebar', { sidebar_open: open, sidebar_mobile_open: mobileOpen })}>
      <div className='sidebar_bodyDrop' onClick={handleCloseMobileMenu} />
      <div className='sidebar_top'>
        <div className='sidebar_user'>
          <Link to={routes.settings.path} onClick={handleCloseMobileMenu} className='sidebar_user_info'>
            <div className='sidebar_user_avatar'>
              <NoAvatarIcon />
            </div>
            <div className='sidebar_user_detail'>
              <div className='sidebar_user_name'>{user?.name} </div>
              <div className='sidebar_user_email'>{user?.email || user?.phone}</div>
            </div>
          </Link>
          <div className='sidebar_user_actions'>
            <button onClick={() => logout.mutate()}>
              <LogOutIcon />
            </button>
          </div>
        </div>
        <div className='sidebar_nav'>
          <ul>
            <li>
              <NavLink to={routes.dashboard.path} exact onClick={handleCloseMobileMenu}>
                <div className={'sidebar_nav_icon'}>
                  <UserIcon />
                </div>
                <div className={'sidebar_nav_label'}>{t('menu_my_announcement')}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to={routes.inactive.path} onClick={handleCloseMobileMenu}>
                <div className={'sidebar_nav_icon'}>
                  <CargoIcon />
                </div>
                <div className={'sidebar_nav_label'}>{t('menu_inactive')}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to={routes.settings.path} onClick={handleCloseMobileMenu}>
                <div className={'sidebar_nav_icon'}>
                  <SettingsIcon />
                </div>
                <div className={'sidebar_nav_label'}>{t('menu_settings')}</div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='sidebar_bottom'>
        <ChangeLanguageMenu short={!open} />
      </div>
    </div>
  );
};

export default SidebarMenu;
