import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import UserContext from 'containers/layout/context/user';

import './index.css';
import RouterConfig from './router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  const [loginState, setLoginState] = useState<boolean>(false);
  const [user, setUser] = useState(null);

  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider value={{ login: loginState, user, setLoginState, setUser }}>
          <RouterConfig />
        </UserContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
