import classNames from 'classnames';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import { getCookie } from 'utils/cookie';

import { ReactComponent as LanguageIcon } from 'icons/language.svg';

import { defaultLang, lang } from '../../constants';
import './style.scss';

interface Props {
  short: boolean;
}
const ChangeLanguageMenu = ({ short }: Props): JSX.Element => {
  const element = document.querySelector('.main_body');
  const { i18n } = useTranslation('common');

  const [controlledVisible, setControlledVisible] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
    {
      placement: 'top-end',
      interactive: true,
      trigger: 'click',
      visible: controlledVisible,
      onVisibleChange: setControlledVisible
    },
    {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: element
          }
        }
      ]
    }
  );

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    setControlledVisible(false);
    // @ts-ignore
    if (window?.HelpCrunch) {
      // @ts-ignore set lang
      window?.HelpCrunch('setPhraseList', getCookie('NEXT_LOCALE'));
    }
  };

  return (
    <div className='change_lang'>
      <button ref={setTriggerRef} className={classNames({ active: visible, short: short })}>
        <LanguageIcon />
        {/*// @ts-ignore*/}
        <span>{lang?.[`${i18n?.language}` as string] ? i18n.language.toUpperCase() : defaultLang.toUpperCase()}</span>
      </button>
      {visible &&
        ReactDOM.createPortal(
          <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
            <ul>
              {Object.keys(lang).map((langKey) => (
                <li key={langKey}>
                  {/* @ts-ignore */}
                  <button
                    onClick={() => changeLang(langKey)}
                    className={classNames({ change_lang_active: langKey === i18n.language })}
                  >
                    {/* @ts-ignore */}
                    {lang?.[langKey]}
                  </button>
                </li>
              ))}
            </ul>
          </div>,
          document.body
        )}
    </div>
  );
};

export default ChangeLanguageMenu;
