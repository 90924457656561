import React, { FC } from 'react';

import './style.scss';

const PageLoader: FC = () => {
  return (
    <div className={'spinner-wrapper'}>
      <div className={'spinner'} />
    </div>
  );
};

export default PageLoader;
