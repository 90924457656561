export const defaultLang = 'uk';

export const lang = {
  uk: 'Українська',
  en: 'English',
  ru: 'Русский',
  pl: 'Polski',
  //  cs: 'Čeština',
  sk: 'Slovenský jazyk',
  de: 'Deutsch',
  bg: 'Български език',
  ka: 'ქართული ენა',
  // lt: 'Lietuvių kalba',
  ro: 'Limba română',
  tr: 'Türkçe',
  az: 'Azərbaycan dili'
};
