import { isObject } from '@greatsumini/react-facebook-login';
import classes from 'classnames';
import { sessionExist } from 'network/loginActions';
import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from 'router';

import PageLoader from '../../components/PageLoader';
import { getCookie } from '../../utils/cookie';
import { API } from './api';
import LoginHeader from './components/LoginHeader';
import SidebarMenu from './components/SidebarMenu';
import UserHeader from './components/UserHeader';
import UserContext from './context/user';
import './style.scss';

const Layout: FC<{ children: JSX.Element | string }> = ({ children }) => {
  const { login, setLoginState, setUser } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const currentRoute =
    routes[`${Object.keys(routes).find((key) => matchPath(location.pathname, routes[key].path)?.isExact)}`];

  const { isLoading, data } = useQuery(['current_user'], async () => API.getCurrentUser(), {
    refetchOnWindowFocus: false,
    enabled: sessionExist(),
    onError: () => {
      setLoginState(false);
    },
    onSuccess: (data) => {
      setLoginState(true);
      setUser(data);
    }
  });

  useEffect(() => {
    // @ts-ignore
    if (window?.HelpCrunch) {
      // @ts-ignore set lang
      window?.HelpCrunch('setPhraseList', getCookie('NEXT_LOCALE'));

      if (data?.id) {
        const user = {
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          custom_data: {
            refId: data?.id,
            adminUrl: `https://admin.lardi-trans.com/admin/new-admin/individuals/user/${data.id}`
          }
        };
        // @ts-ignore
        window?.HelpCrunch('updateUser', user);
      }
    }
  }, [login, data?.id]);

  if (isLoading)
    return (
      <div className={'full_page'}>
        <PageLoader />
      </div>
    );

  if (login && !data?.verified)
    return (
      <Suspense fallback={<PageLoader />}>
        <main className={'main'}>
          <UserHeader toggleMenu={() => {}} notVerify />
          <div className={'main_body'}>
            <div className={classes('main_content')}>{children}</div>
          </div>
          <ToastContainer autoClose={2000} pauseOnHover={false} pauseOnFocusLoss={false} />
        </main>
      </Suspense>
    );

  if (!login || (isObject(currentRoute) && !currentRoute?.isPrivate))
    return (
      <Suspense fallback={<PageLoader />}>
        <main
          className={'main'}
          style={{
            backgroundImage: `url('${process.env.REACT_APP_BASEPATH}/img/bg_pattern.png')`,
            backgroundPosition: 'center center'
          }}
        >
          <LoginHeader />
          <div className={'main_body'}>{children}</div>
          <ToastContainer autoClose={2000} pauseOnHover={false} pauseOnFocusLoss={false} />
        </main>
      </Suspense>
    );

  return (
    <Suspense fallback={<PageLoader />}>
      <main className={'main'}>
        <UserHeader
          toggleMenu={() => {
            if (window.innerWidth < 800) {
              setMobileMenuOpen((p) => !p);
            }
            setMenuOpen((p) => !p);
          }}
        />
        <div className={'main_body'}>
          <SidebarMenu
            open={menuOpen}
            mobileOpen={mobileMenuOpen}
            handleCloseMobileMenu={() => setMobileMenuOpen(false)}
          />
          <div
            className={classes('main_content', {
              main_content_small: menuOpen
            })}
          >
            {children}
          </div>
        </div>
        <ToastContainer autoClose={2000} pauseOnHover={false} pauseOnFocusLoss={false} />
      </main>
    </Suspense>
  );
};

export default Layout;
