import client from 'network';

import {
  CategoryGroup,
  CommonResponse,
  Country,
  CurrencyTranslates,
  Freight,
  FreightListResponse,
  FreightSingle,
  PropertiesResponse,
  RequestFreightList,
  TownSingle
} from './interface';

export const API = {
  async getFreights(params: RequestFreightList): Promise<FreightListResponse> {
    return await client.get('/log/freights', { params }).then(({ data }) => data);
  },
  async getCategoryGroups(): Promise<CategoryGroup[]> {
    return await client.get('/references/adcategory-groups').then(({ data }) => data);
  },
  async getCountries(): Promise<Country[]> {
    return await client
      .get('/references/countries')
      .then(({ data }) => data.filter((c: Country) => c.sign.toLowerCase() !== 'ru'));
  },
  async getCommon(): Promise<CommonResponse> {
    return await client.get('/references/common').then(({ data }) => ({
      ...data,
      countries: data.countries.filter((c: Country) => c.sign.toLowerCase() !== 'ru')
    }));
  },
  async getProperties(): Promise<PropertiesResponse> {
    return await client.get('/references/properties').then(({ data }) => data);
  },
  async getCurrencies(): Promise<CurrencyTranslates[]> {
    return await client.get('/references/currencies').then(({ data }) => data);
  },
  async createFreights(freight: Freight): Promise<any> {
    return await client.post('/log/freights', freight).then(({ data }) => data);
  },
  async deactivateFreight(id: number): Promise<any> {
    return await client.post(`/log/freights/${id}/trash`).then(({ data }) => data);
  },
  async activateFreight(id: number): Promise<any> {
    return await client.post(`/log/freights/${id}/untrash`).then(({ data }) => data);
  },
  async deleteFreight(id: number): Promise<any> {
    return await client.delete(`/log/freights/${id}`).then(({ data }) => data);
  },
  async getSingleFreight(id: string): Promise<FreightSingle> {
    return await client.get(`/log/freights/${id}`).then(({ data }) => data);
  },
  async updateFreight(id: string, freight: Freight, untrash: boolean): Promise<any> {
    return await client.put(`/log/freights/${id}`, { ...freight, untrash }).then(({ data }) => data);
  },
  async searchTown(query: string, countrySign: string): Promise<TownSingle[]> {
    return await client
      .get(`/references/towns`, { params: { query, countrySign, includeOwnTown: true } })
      .then(({ data }) => data);
  }
};
