import classes from 'classnames';
import React, { FC, SyntheticEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultSelect, { Props as DefaultSelectProps, components } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { ReactComponent as DropDownIndicator } from 'icons/dropdown-indicator.svg';

interface Props {
  selectType?: SelectType;
  selectSize?: 'small' | 'medium' | 'large';
  placeholder?: string;
  hideDropdownIndicator?: boolean;
  wrapperClassName?: string;
  error?: string;
  errorBorder?: boolean;
  label?: JSX.Element | string;
  className?: string;
  options: any[];
  components?: any;
  isRequired?: boolean;
  hint?: string;
  wrapperProps?: Record<string, unknown>;
  onBlur?: (e: SyntheticEvent) => void;
  onFocus?: (e: SyntheticEvent) => void;
  selectOption?: (opt: Option) => void;
  //onChange?: (val: Option) => void;
}

export enum SelectType {
  AsyncCreatableSelect = 'AsyncCreatableSelect',
  CreatableSelect = 'CreatableSelect',
  AsyncSelect = 'AsyncSelect',
  DefaultSelect = 'DefaultSelect'
}

const selects = {
  [SelectType.AsyncCreatableSelect]: AsyncCreatableSelect,
  [SelectType.CreatableSelect]: CreatableSelect,
  [SelectType.AsyncSelect]: AsyncSelect,
  [SelectType.DefaultSelect]: DefaultSelect
};

export interface Option {
  readonly label: string;
  readonly value: string | number | boolean;
}

type UnionSelectType =
  | AsyncCreatableProps<Option, boolean, GroupBase<Option>>
  | CreatableProps<Option, boolean, GroupBase<Option>>
  | AsyncProps<Option, boolean, GroupBase<Option>>
  | DefaultSelectProps<Option, boolean, GroupBase<Option>>;

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIndicator />
    </components.DropdownIndicator>
  );
};

export type SelectProps = Props & UnionSelectType;
export const LabeledSelect: FC<SelectProps> = ({
  selectType = SelectType.DefaultSelect,
  wrapperClassName = '',
  error,
  errorBorder = false,
  placeholder = '',
  label = '',
  isRequired,
  hint,
  wrapperProps = {},
  selectSize = 'medium',
  hideDropdownIndicator,
  ...props
}) => {
  const { t } = useTranslation('common');
  const ref = useRef(null);
  const Select = selects[selectType as SelectType] as any;
  const handleBlur = useCallback(
    (event: SyntheticEvent) => {
      // ReactTooltip.hide(ref.current);
      // ReactTooltip.rebuild();
      props?.onBlur?.(event);
    },
    [props]
  );
  const handleFocus = useCallback(
    (event: SyntheticEvent) => {
      //  ReactTooltip.show(ref.current);
      props?.onFocus?.(event);
    },
    [props]
  );

  return (
    <div
      className={classes(wrapperClassName, 'labeled-select', {
        'labeled-select_error': !!error || errorBorder
      })}
      {...wrapperProps}
      data-tip={hint}
      data-event-off='focus'
      data-event='focus'
      ref={ref}
    >
      <div className='labeled-select__wrapper'>
        {label && (
          <label className='labeled-select__label'>
            {label} {isRequired && <sup>*</sup>}
          </label>
        )}
        <Select
          {...props}
          classNamePrefix={classes('lrd-ui-select')}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className={classes(props.className, 'labeled-select__select', `labeled-select__select--${selectSize}`, {
            'labeled-select__select--no-dropdown-indicator': hideDropdownIndicator
          })}
          placeholder={placeholder}
          components={{ ...props.components, DropdownIndicator }}
          noOptionsMessage={() => t('select_nothing_found')}
          loadingMessage={() => t('select_loading') + '...'}
        />
        {error && <span className='labeled-select__error'>{error}</span>}
      </div>
    </div>
  );
};
