import React, { FunctionComponent, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route } from 'react-router-dom';

import UserContext from 'containers/layout/context/user';

import ErrorPage from 'components/ErrorPage';
import PageLoader from 'components/PageLoader';

import { routes } from './index';
import { RouteSingle } from './interface';

const GenerateRoute: FunctionComponent<RouteSingle> = ({ path, Component, isPrivate }) => {
  const { login, user } = useContext(UserContext);

  // if user not login and try watch private pages
  if (isPrivate && !login) {
    return <Redirect to={routes.login.path} />;
  }

  // if user not verified he can watch only verification page and all login flow
  if (isPrivate && !user?.verified && path !== routes.verification.path) {
    return <Redirect to={routes.verification.path} />;
  }

  // if user verified and open verification page
  if (user?.verified && path === routes.verification.path) {
    return <Redirect to={routes.dashboard.path} />;
  }

  return (
    <Route
      strict
      path={path.slice(0, -1)}
      component={() => (
        <Suspense fallback={<PageLoader />}>
          <ErrorBoundary fallback={<ErrorPage />}>
            <Component />
          </ErrorBoundary>
        </Suspense>
      )}
    />
  );
};

export default GenerateRoute;
