import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const hiddenLocales = ['ge', 'lt', 'cs'];
export const locales = ['uk', 'en', 'ru', 'pl', 'cs', 'sk', 'de', 'bg', 'ge', 'lt', 'ro', 'tr', 'az', 'ka'];

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '/log/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false
    },
    debug: false,
    supportedLngs: [...locales, ...hiddenLocales],
    detection: {
      caches: ['cookie'],
      lookupCookie: 'NEXT_LOCALE',
      order: ['cookie']
    }
  });

export default i18n;
