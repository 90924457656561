import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'router';

import './style.scss';

export interface ErrorPageProps {
  code?: number;
  message?: string;
  withoutActions?: boolean;
}

export const ErrorPage: FC<ErrorPageProps> = ({ code = 418, message, withoutActions }) => {
  const { t } = useTranslation('common');
  const history = useHistory();

  const statusesByCode: { [key: number]: string } = {
    404: t('errors_page_not_found'),
    403: t('errors_no_access'),
    500: t('errors_server_error'),
    418: t('errors_something_wrong')
  };

  const imagesByCode: { [key: number]: string } = {
    404: '/img/errors/container.png',
    403: '/img/errors/blured-container.png',
    500: '/img/errors/incident-sign.png',
    418: '/img/errors/incident-sign.png'
  };

  // @ts-ignore
  return (
    <div className='lrd-ui--error'>
      <div className='lrd-ui--error__content'>
        <div className='lrd-ui--error__image'>
          <img
            src={process.env.REACT_APP_BASEPATH + (imagesByCode?.[code] || imagesByCode[500])}
            alt={code?.toString()}
          />
        </div>
        <div className='lrd-ui--error__info'>
          <div className='lrd-ui--error__status'>
            <p className='lrd-ui--error__status__code'>{code}</p>
            <p className='lrd-ui--error__status__message'>{statusesByCode[code]}</p>
          </div>
          <p className='lrd-ui--error__description'>{message}</p>
          <div className='lrd-ui--error__actions'>
            {code === 500 && (
              <button className='button button_large button_primary' onClick={() => window.location.reload()}>
                {t('button_reload_page')}
              </button>
            )}
            {withoutActions ? (
              <button className='button button_large button_primary' onClick={() => history.goBack()}>
                {t('button_back')}
              </button>
            ) : (
              <Link to={routes.dashboard.path} className='button button_large button_primary'>
                {t('go_on_main_button')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
