export const LOCAL_STORAGE_API_KEY_ACCESSOR = 'sessionId';

export const sessionExist = (): boolean => {
  return !!localStorage.getItem(LOCAL_STORAGE_API_KEY_ACCESSOR);
};

export const userLogin = (sessionId: string) => {
  localStorage.setItem(LOCAL_STORAGE_API_KEY_ACCESSOR, sessionId);
};

export const userLogout = () => {
  localStorage.removeItem(LOCAL_STORAGE_API_KEY_ACCESSOR);
};
